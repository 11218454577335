import React, { useEffect, useState } from 'react';
import {
  AboutSection,
  CarouselComponent,
  DonationType,
  DonationWaysSeparator,
  DonatNowSeparation,
  HistorySeparation,
  OurMission,
  TrioCardComponent,
} from './components/HomeComponent';
import Top3Donations from './components/Top3Donations';
import { Link } from 'react-router-dom';
import useHomeScreenData from '../../hooks/useHomeScreenData';
import { getAssetUrl } from '../../utils/constants';
import YoutubeVideoPlayerModal from './components/YoutubeVideoPlayerModal';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
  Home_aboutus,
  Home_banner_cause,
  Home_banner_contact,
  Home_banner_donation,
  Home_dollar_sign,
  Home_dollar,
  Home_donate_by_1,
  Home_donate_by_2,
  Home_donate_by_3,
  Home_donate_by_4,
  Home_donate_by_call,
  Home_play_button,
  Home_qurbani,
  Home_title_1,
  Home_title_2,
  Home_title_3,
  Home_title_4,
  Home_title_5,
  Home_title_6,
  Home_video_bg,
  Home_zakat_hands,
  Home_zakat,
  Home_zelle,
  Home_default_bg,
} from '../../utils/Images';
import useScreen from '../../hooks/useScreen';
import NoIndexNoFollow from '../../components/NoIndexNoFollow';

const Home = () => {
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const { width } = useScreen();

  useEffect(() => {
    document.title = 'Home | Ummah Relief';
  }, []);

  const {
    aboutUsData,
    carouselData,
    top3CausesData,
    donateByData,
    donationTypesData,
    ourMissionData,
    bannerCauseData,
    bannerContactData,
    bannerDonationData,
    loadindHomeData,
  } = useHomeScreenData();

  /* useEffect(() => {
    const moveOnScroll = () => {
      const scrollPosition = window.pageYOffset;
      const background = document.querySelector('.bg-img-scroll-section');
      background.style.backgroundPositionY = `${scrollPosition}px`;
    };

    window.addEventListener('scroll', moveOnScroll);
    return () => {
      window.removeEventListener('scroll', moveOnScroll);
    };
  }, []); */

  return (
    <>
      <NoIndexNoFollow />
      <CarouselComponent carouselData={carouselData} />
      <Top3Donations top3CausesData={top3CausesData} />
      {/* <TrioCardComponent /> */}

      {/* OUR JOURNEY Banner */}

      <section
        className="bg-img-scroll-section"
        style={{
          backgroundImage: `url(${getAssetUrl(aboutUsData?.video_bg)})`,
        }}
      >
        <div className="bg-img-scroll">
          <div className="my-5 py-4">
            <div className="my-2">
              <div
                className="d-flex flex-column align-items-center gap-3"
                style={{ lineHeight: '2rem' }}
              >
                <div className="fs-2 fw-bold text-uppercase text-white icon-shadow px-1">
                  {'OUR JOURNEY' || bannerDonationData?.title}
                </div>
                <div
                  className="fs-5 fw-bold text-white icon-shadow px-1 text-center"
                  style={{ fontFamily: 'sans-serif', letterSpacing: '4px' }}
                >
                  {
                    'Since 1994, Ummah has helped people relieve some of the suffering in the world.'
                  }
                </div>
                <Link
                  to={bannerDonationData?.button || '/donations/oneTime'}
                  className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                  style={{
                    borderRadius: '50px',
                    borderWidth: '2px',
                    fontSize: '12px',
                    textDecoration: 'none',
                  }}
                >
                  <span className="icon-shadow">Donate Now </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AboutSection
        aboutUsData={aboutUsData}
        onImageClick={() => {
          setVideoUrl(aboutUsData?.video || '');
          setIsOpenVideoModal(true);
        }}
      />

      {/* Cause Banner */}
      <section
        className="bg-img-scroll-section"
        style={{
          backgroundImage: `url(${getAssetUrl(bannerCauseData?.bg_image)})`,
        }}
      >
        <div className="bg-img-scroll">
          <div className="my-5 py-4">
            <div className="my-2">
              <div
                className="d-flex flex-column align-items-center gap-3"
                style={{ lineHeight: '2rem' }}
              >
                <div className="fs-2 fw-bold text-uppercase text-white icon-shadow px-1">
                  {bannerCauseData?.title || 'CAUSES'}
                </div>
                <div
                  className="fs-5 fw-bold text-white icon-shadow px-1 text-center"
                  style={{ fontFamily: 'sans-serif', letterSpacing: '4px' }}
                >
                  {bannerCauseData?.description ||
                    'We come up with new, unique ideas to raise the bar for humanity.'}
                </div>
                <Link
                  to={bannerCauseData?.button || '/causes/1'}
                  className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                  style={{
                    borderRadius: '50px',
                    borderWidth: '2px',
                    fontSize: '12px',
                    textDecoration: 'none',
                  }}
                >
                  <span className="icon-shadow">Read More </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <HistorySeparation /> */}
      <OurMission ourMissionData={ourMissionData} />

      <section
        className="bg-img-scroll-section"
        style={{
          backgroundImage: `url(${getAssetUrl(bannerDonationData?.bg_image)})`,
        }}
      >
        <div className="bg-img-scroll">
          <div className="my-5 py-4">
            <div className="my-2">
              <div
                className="d-flex flex-column align-items-center gap-3"
                style={{ lineHeight: '2rem' }}
              >
                <div className="fs-2 fw-bold text-uppercase text-white icon-shadow px-1">
                  {bannerDonationData?.title || 'DONATION'}
                </div>
                <div
                  className="fs-5 fw-bold text-white icon-shadow px-1 text-center"
                  style={{ fontFamily: 'sans-serif', letterSpacing: '4px' }}
                >
                  {bannerDonationData?.description ||
                    'Organized network providing humanitarian services across the World.'}
                </div>
                <Link
                  to={bannerDonationData?.button || '/donations/oneTime'}
                  className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                  style={{
                    borderRadius: '50px',
                    borderWidth: '2px',
                    fontSize: '12px',
                    textDecoration: 'none',
                  }}
                >
                  <span className="icon-shadow">Donate Now </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  <DonatNowSeparation /> */}
      <DonationType donationTypesData={donationTypesData} />

      <section
        className="bg-img-scroll-section"
        style={{
          backgroundImage: `url(${getAssetUrl(bannerContactData?.bg_image)})`,
        }}
      >
        <div className="bg-img-scroll">
          <div className="my-5 py-4">
            <div className="my-2">
              <div
                className="d-flex flex-column align-items-center gap-3"
                style={{ lineHeight: '2rem' }}
              >
                <div className="fs-2 fw-bold text-uppercase text-white icon-shadow px-1">
                  {bannerContactData?.title || 'CONTACT'}
                </div>
                <div
                  className="fs-5 fw-bold text-white icon-shadow px-1 text-center"
                  style={{ fontFamily: 'sans-serif', letterSpacing: '4px' }}
                >
                  {bannerContactData?.description ||
                    'Click here for questions, opportunities or ways to get involved. Together we are Ummah!'}
                </div>
                <Link
                  to={bannerContactData?.button || '/contact'}
                  className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                  style={{
                    borderRadius: '50px',
                    borderWidth: '2px',
                    fontSize: '12px',
                    textDecoration: 'none',
                  }}
                >
                  <span className="icon-shadow"> Contact Now </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DonationWaysSeparator donateByData={donateByData} />

      <YoutubeVideoPlayerModal
        open={isOpenVideoModal}
        onClose={() => {
          setIsOpenVideoModal(false);
          setVideoUrl('');
        }}
        url={videoUrl}
      />
    </>
  );
};

export default Home;
