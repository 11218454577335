import React, { useEffect, useState } from 'react';

import CardNumberValidator from '@shaaditech/react-cc-validator';
import FormValidator from '../../../utils/validation';
import { MaskedInput } from '../../../components';
import Select from 'react-select';
import moment from 'moment';
import { nameRegex } from '../../../utils/constants';

const CreditCardForm = ({
  creditCardInfo,
  setCreditCardInfo,
  submissionState,
  extendSubmissionState,
}) => {
  const [paymentType] = useState('stripe');
  const [nameOnCard, setNameOnCard] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardBrand, setCardBrand] = useState('');
  const [expYear, setExpYear] = useState('');
  const [cscCode, setCSCCode] = useState('');

  const [errors, setErrors] = useState({});
  const [expMonthList, setExpMonthList] = useState([]);
  const [expYearList, setExpYearList] = useState([]);
  const [expMonth, setExpMonth] = useState('');
  const [expiredMessage, setExpiredMessage] = useState('');

  const expMonthValidator = () => {
    if (expMonth === 0) {
      setErrors((old) => {
        return { ...old, exp_month: 'Expiry Month is required' };
      });
    } else {
      setErrors((old) => {
        return { ...old, exp_month: '' };
      });
    }
  };

  const validateMe = () => {
    const nc = FormValidator.handleOnBlur(
      { target: { id: 'name_on_card', value: nameOnCard } },
      setErrors
    );
    const cn = FormValidator.handleOnBlur(
      { target: { id: 'card_number', value: cardNumber, name: cardBrand } },
      setErrors
    );
    const em = FormValidator.handleOnBlur(
      { target: { id: 'exp_month', value: expMonth } },
      setErrors
    );
    const ey = FormValidator.handleOnBlur(
      { target: { id: 'exp_year', value: expYear } },
      setErrors
    );
    const cc = FormValidator.handleOnBlur(
      { target: { id: 'csc_code', value: cscCode, name: cardBrand } },
      setErrors
    );
    return nc && cn && em && ey && cc && expiredMessage === '';
  };

  useEffect(() => {
    if (submissionState === 1) {
      if (validateMe()) {
        extendSubmissionState();
      } else {
        // console.log('CreditCardForm | Validation failed');
      }
    }
  }, [submissionState]);

  useEffect(() => {
    const months = moment.months();

    const currentYear4 = moment().year();
    const currentYear2 = parseInt(moment(moment()).format('YY'));
    const monthList = months.map((month, index) => {
      return {
        value: index + 1,
        label: month,
      };
    });
    const years = [];
    for (var i = 0; i < 7; i++) {
      years.push({
        value: currentYear4 + i,
        label: currentYear2 + i,
      });
      setExpYearList(years);
    }
    setExpMonthList(monthList);
  }, []);

  useEffect(() => {
    const obj = {
      name: nameOnCard,
      number: cardNumber,
      exp_month: expMonth,
      exp_year: expYear,
      cvc: cscCode,
      card_type: cardBrand,
    };

    if (setCreditCardInfo !== undefined) {
      setCreditCardInfo({ ...obj });
    }
  }, [paymentType, nameOnCard, cardNumber, expMonth, expYear, cscCode]);

  const checkCreditCardExpiration = () => {
    const currentDate = new Date();
    const currentYear = parseInt(currentDate.getFullYear());
    const currentMonth = parseInt(currentDate.getMonth()) + 1;
    if (currentYear === parseInt(expYear) && currentMonth > parseInt(expMonth))
      setExpiredMessage('Credit Card is expired');
    else setExpiredMessage('');

    /* const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    let message = '';

    if (expYear !== null && expMonth !== null) {
      if (expYear === currentYear) {
        if (expMonth < currentMonth) {
          message = 'Credit Card is expired';
        } else {
          message = '';
        }
      }
    }
    setErrors((old) => {
      return { ...old, expiryMonth: message };
    }); */
  };
  // console.log(expMonth)

  useEffect(() => {
    checkCreditCardExpiration();
  }, [expMonth, expYear]);

  return (
    <>
      <h4 className='mt-4 fw-bold pt-5 pt-lg-0 pt-xl-0 pt-xxl-0'>
        Debit/Credit Card Information
      </h4>
      <hr />

      <div className='form-group mb-3'>
        <label htmlFor='name_on_card' className='form-label text-dark'>
          Name on card <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          name='name_on_card '
          onChange={(e) => {
            nameRegex.test(e.target.value) &&
              setNameOnCard(e.target.value.replace(/^\d+$/, ''));
          }}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          id='name_on_card'
          className='form-control'
          value={nameOnCard}
          placeholder='Name on card *'
          required={true}
        />
        {errors.cardName !== '' && (
          <small className='text-danger'>{errors.cardName}</small>
        )}
      </div>

      <div className='form-group mb-3'>
        <div>
          <CardNumberValidator>
            {({ isValid, cardType, getInputProps }) => {
              setCardBrand(cardType);
              return (
                <div>
                  <MaskedInput
                    label='Card Number'
                    id='card_number'
                    required={true}
                    placeholder='xxxx xxxx xxxx xxxx'
                    mask='9999 9999 9999 9999'
                    name={cardBrand}
                    // key={cardBrand}
                    min={0}
                    max={19}
                    onBlur={(e) => {
                      FormValidator.handleOnBlur(e, setErrors);
                      // setCardNumber(getInputProps().value);
                      // console.log(
                      //   "getInputProps value onBlur",
                      //   getInputProps().onChange
                      // );
                      // console.log("event value onBlur", e.target.value);
                    }}
                    value={cardNumber}
                    {...getInputProps()}
                    onChange={(e) => {
                      getInputProps().onChange(e);
                      setCardNumber(e.target.value);
                      setCSCCode('');
                      setCardBrand(cardType);
                      // console.log("value", getInputProps().onChange);
                    }}
                    cardType={cardType}
                    isValid={isValid}
                    isCreditCardNumber={true}
                    onFo
                  />
                  {
                    // console.log(getInputProps().value)
                  }
                  {/* <input type="text" {...getInputProps()} /> */}
                  {/* <div>{isValid && cardType}</div> */}
                  {getInputProps().value === '' ? (
                    <></>
                  ) : (
                    isValid || (
                      <small className='text-danger'>
                        Card number is invalid !
                      </small>
                    )
                  )}

                  {getInputProps().value === '' ? (
                    <></>
                  ) : (
                    isValid && (
                      <small className='text-success'>
                        Card number is valid
                        <span className='fw-bold ps-2'>
                          <i className='fa-solid fa-check'></i>
                          {/* <i className='fa-solid fa-check'></i>
                        <i className='fa-solid fa-check'></i> */}
                        </span>
                      </small>
                    )
                  )}
                </div>
              );
            }}
          </CardNumberValidator>
        </div>
        {/* <MaskedInput
          label="Card Number"
          id="card_number"
          required={true}
          mask="9999 9999 9999 9999"
          name="card_number"
          min={0}
          max={19}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          onChange={(e) => setCardNumber(FormValidator.maxLengthCheck(e))}
          value={cardNumber}
        />
        {errors.cardNoError !== "" && (
          <small className="text-danger">{errors.cardNoError}</small>
        )} */}
      </div>

      <div className='row mb-3'>
        <div className='form-group col-md-6'>
          <label htmlFor='exp_month' className='form-label text-dark'>
            Expiry Month <span className='text-danger'>*</span>
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            required={true}
            name='exp_month'
            id='exp_month'
            onChange={(e) => setExpMonth(e.target.value)}
            value={expMonth}
            onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          >
            <option value=''>Select expiry month</option>
            {expMonthList &&
              expMonthList.length > 0 &&
              expMonthList.map((expMonth, index) => (
                <option value={expMonth.value} key={index}>
                  {expMonth.value}
                </option>
              ))}
          </select>
          {/* <Select
            className="basic-single"
            classNamePrefix="select"
            // defaultInputValue={expMonthList[1]}
            // defaultValue={expMonthList[1]}
            // defaultMenuIsOpen={expMonthList[1]}
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            onBlur={
              (e) => expMonthValidator()
              // alert(expMonth.value)
              // FormValidator.handleCreditCard(e, setErrors, "exp_month")
            }
            onChange={(e) => setExpMonth(e)}
            // onChange={(e) =>
            //   setExpMonth(e ? e : expMonthList[0])
            // }
            // value={expMonth !== "" && expMonth}
            name="exp_month"
            id="exp_month"
            options={expMonthList}
            required={true}
          /> */}
          {errors.exp_month && errors.exp_month !== '' && (
            <small className='text-danger'>{errors.exp_month}</small>
          )}

          {/* {(expMonth === 0 || !expMonth) && (
            <small className="text-muted">Expiry Month is required</small>
          )} */}
        </div>

        <div className='form-group col-md-6'>
          <label htmlFor='exp_year' className='form-label text-dark'>
            Expiry Year <span className='text-danger'>*</span>
          </label>
          <select
            className='form-select'
            aria-label='Default select example'
            required={true}
            name='exp_year'
            id='exp_year'
            onChange={(e) => setExpYear(e.target.value)}
            value={expYear}
            onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          >
            <option value=''>Select expiry year</option>
            {expYearList &&
              expYearList.length > 0 &&
              expYearList.map((expYear, index) => (
                <option value={expYear.value} key={index}>
                  {expYear.label}
                </option>
              ))}
          </select>

          {errors.expiryMonth !== '' && (
            <div>
              <small className='text-danger'>{errors.expiryMonth}</small>
            </div>
          )}
          {errors.expiryYear !== '' && (
            <div>
              <small className='text-danger'>{errors.expiryYear}</small>
            </div>
          )}
          {expiredMessage !== '' && (
            <div>
              <small className='text-danger'>{expiredMessage}</small>
              <br />
            </div>
          )}
        </div>
      </div>
      <div className='form-group mb-3'>
        <MaskedInput
          label='Card Verification Code'
          id='csc_code'
          placeholder='xxx'
          required={true}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          // mask={cardBrand !== "american-express" ? "999" : "9999"}
          mask={'9999'}
          name={cardBrand}
          onChange={(e) => {
            // console.log('cardBrand=>', cardBrand);
            setCSCCode(e.target.value);
          }}
          value={cscCode}
        />

        {errors.cvc !== '' && (
          <small className='text-danger'>{errors.cvc}</small>
        )}
      </div>
    </>
  );
};

export default CreditCardForm;
