import {
  DonationForm,
  DonnationPoster,
  SpecialRequestForm,
} from './components/DonationComponent';
import React, { useCallback, useEffect, useState } from 'react';
import DeclineInfo from './components/DonationComponent/DeclineInfo';
import CreditCardForm from './components/CreditCardForm';
import DonationService from '../../services/donation.service';
import EmployeeMatchingGiftProgram from './components/EmployeeMatchingGiftProgram';
import ErrorFormat from '../../utils/ErrorFormatting';
import FormValidator from '../../utils/validation';
import PersonalInfoForm from './components/PersonalInfoForm';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { wait } from '../../utils/constants';
import ECheckoutForm from '../../components/ECheckoutForm';

import ACHPaymentForm from './components/ACHPaymentForm';
import EcheckRadioButtons from '../../components/EcheckRadioButtons';
import PlaidLink from '../../components/CreatePlaidLink';

const OneTimeDonation = () => {
  const navigate = useNavigate();
  const [donationType, setDonationType] = useState([]);
  const [initialDonationType, setInitialDonationType] = useState([]);
  const [specialRequest, setSpecialRequest] = useState([]);
  const [specialRequestBackup, setSpecialRequestBackup] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalSpecialAmount, setTotalSpecialAmount] = useState(0);
  const [totalSpecialDonations, setTotalSpacialDonation] = useState([]);
  //const [totalDonation, setTotalDonation] = useState([]);
  const [totalDonation, setTotalDonation] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [finalDonationData, setFinalDonationData] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currency] = useState('usd');
  const [errors, setErrors] = useState({});
  const [focus, setFocus] = useState({});
  const [submissionState, setSubmissionState] = useState(0);
  const [isResetForm, setIsResetForm] = useState(false);
  const [employeeMatchingGiftProgram, setEmployeeMatchingGiftProgram] =
    useState({});
  const [isEcheck, setIsEcheck] = useState(false);

  const [token, setToken] = useState(null);
  const [stripeToken, setStripeToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleStripeTokenReceived = (stripeToken) => {
    setStripeToken(stripeToken);
    // console.log({ stripeToken });
  };
  const extendSubmissionState = () => {
    setSubmissionState((state) => state + 1);
  };

  const [achInfo, setAchInfo] = useState({});

  const getDonationType = () => {
    DonationService.getOneTimeDonationTypes().then((res) => {
      setDonationType(res);
      setInitialDonationType(res);
    });
    DonationService.getSpecialDonationTypes().then((res) => {
      setSpecialRequest(res);
      setSpecialRequestBackup(res);
    });
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const startSubmit = () => {
    setSubmissionState(1);
    setTimeout(() => {
      setSubmissionState(0);
    }, 1000);
  };

  useEffect(() => {
    // if (submissionState < 2) {
    //   debugger;
    // }

    const totalForms = 1;
    if (isEcheck && stripeToken) {
      // extendSubmissionState();
      setAchInfo({ stripeToken });
    }
    if (submissionState >= totalForms + 1) {
      if (isEcheck) {
        if (stripeToken) {
          handleSubmit();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You did not connect a bank account!',
          });
        }
      } else {
        handleSubmit();
      }
    }
  }, [submissionState]);

  const checkIsDonation = () => {
    {
      startSubmit();
    }
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    setIsSubmitting(true);

    if (finalDonationData.card.exp_month === 0) {
      setIsLoading(false);
      setIsSubmitting(false);
      const expMonthField = document.getElementById('exp_month');
      const expMonthInput = expMonthField.getElementsByTagName('input');
      return expMonthInput[0].focus();
    }

    if (
      finalDonationData.oneTime.length === 0 &&
      finalDonationData.special.length === 0
    ) {
      setIsLoading(false);
      setIsSubmitting(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You did not select any donatation amount!',
      });
    } else {
      DonationService.submitDonation({
        matchingGift: employeeMatchingGiftProgram,
        personal: personalInfo,
        card: creditCardInfo,
        ach: achInfo,
        stripe_token: stripeToken,
        special: totalSpecialDonations,
        oneTime: totalDonation,
        isEcheck,
        donation_of: {
          oneTime: true,
          monthly: false,
          zakat_fitra_donation: false,
          qurbani: false,
          cause: false,
          special: totalSpecialDonations.length > 0,
        },
        payment_through: isEcheck ? 'ach' : 'card',
        currency: currency,
        duration_id: 1,
      })
        .then((data) => {
          // console.log({ data33333: data });
          if (data.payment.status && data.email.status) {
            setIsSubmitting(true);
            let errorExists;
            if (!data.donor.status) errorExists = true;
            else if (!data.cc_details.status) errorExists = true;
            else errorExists = !data.oneTime.status;

            Swal.fire({
              title: 'Thank you for your donation!',
              text: `${errorExists
                ? 'Some information cannot saved successfully but donation done successfully, if it concern do contact Ummah Relief. A verification email has been send to you with details'
                : 'A confirmation email has been sent to the email provided.'
                }`,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((r) => window.location.reload());
          } else {
            setIsLoading(false);
            setIsSubmitting(false);
            let errorMessages;
            if (!data.payment.status) errorMessages = data.payment.message;
            else if (!data.email.status) errorMessages = data.email.message;

            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(errorMessages),
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsSubmitting(false);
          // console.log({ err });
          if (err.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    document.title = 'One Time Donations | Ummah Relief';
    getDonationType();
    scrollTop();
  }, []);

  useEffect(() => {
    setFinalDonationData({
      matchingGift: employeeMatchingGiftProgram,
      personal: personalInfo,
      card: creditCardInfo,
      ach: achInfo,
      stripe_token: stripeToken,
      special: totalSpecialDonations,
      oneTime: totalDonation,
      isEcheck,
      donation_of: {
        oneTime: true,
        monthly: false,
        zakat_fitra_donation: false,
        qurbani: false,
        cause: false,
        special: totalSpecialDonations.length > 0,
      },
      payment_through: isEcheck ? 'ach' : 'card',
      currency: currency,
      duration_id: 1,
    });
    if (
      FormValidator.requiredFieldCheck(finalDonationData, 'oneTime').includes(
        false
      )
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }, [
    totalDonation,
    personalInfo,
    creditCardInfo,
    achInfo,
    currency,
    totalSpecialDonations,
    employeeMatchingGiftProgram,
  ]);

  const isValidated = (values) => {
    if (typeof values === 'object') {
      return Object.values(values).every(
        (x) => x !== null || x !== '' || x !== 0
      );
    } else {
      return values !== '' || values !== null;
    }
  };
  const handleChangeEcheck = async (e) => {
    setIsEcheck(+e.currentTarget.value === 2);
  };
  const resetForm = async () => {
    setIsResetForm(true);
    setDonationType(initialDonationType);
    setTotalAmount(0);
    setTotalSpecialAmount(0);
    setTotalDonation([]);
    setTotalSpacialDonation([]);
    setPersonalInfo({});
    setCreditCardInfo({});
    setAchInfo({});
    setFinalDonationData({});
    setIsEcheck(false);
    setSpecialRequest(specialRequestBackup);
    await wait(1);
    setIsResetForm(false);
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12">
            {!isResetForm && (
              <SpecialRequestForm
                options={specialRequest}
                setOptions={setSpecialRequest}
                handleSum={(sum) => setTotalSpecialAmount(sum)}
                setTotalDonation={(total) => setTotalSpacialDonation(total)}
                totalDonation={totalSpecialDonations}
                totalAmount={totalSpecialAmount}
              />
            )}
            {!isResetForm && (
              <DonationForm
                donationOption={donationType}
                setDonationOption={setDonationType}
                handleSum={(sum) => setTotalAmount(sum)}
                totalAmount={totalAmount}
                setTotalDonation={(total) => setTotalDonation(total)}
                totalDonation={totalDonation}
                title="One Time Donation"
              />
            )}
            <h4 className="fw-bold">
              Total: ${totalAmount + totalSpecialAmount}
            </h4>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 pt-5 pt-lg-0 pt-xl-0 pt-xxl-0">
            {!isResetForm && (
              <PersonalInfoForm
                submissionState={submissionState}
                extendSubmissionState={extendSubmissionState}
                personalInfo={personalInfo}
                setPersonalInfo={(info) => setPersonalInfo(info)}
              />
            )}
            {!isResetForm && (
              <EmployeeMatchingGiftProgram
                setEmployeeMatchingGiftProgram={(info) =>
                  setEmployeeMatchingGiftProgram(info)
                }
              />
            )}
            {/* <label className="lbl">Payment Method: <span className="must">*</span></label>
            <div className="paymentOptions">
              <div className="paymentOption">
                  <input type="radio" id="pm_cc" name="payment_mode" className="payment_mode" value="1" checked={!isEcheck} onChange={handleChangeEcheck} />
                  <label >Debit/Credit Card</label>
              </div>
              <div className="paymentOption">
                  <input type="radio" id="pm_ec" name="payment_mode" className="payment_mode" value="2" onChange={handleChangeEcheck} />
                  <label >ECheck</label>
              </div>
           </div> */}
            <EcheckRadioButtons
              isEcheck={isEcheck}
              setIsEcheck={(echeck) => setIsEcheck(echeck)}
            />
            {!isResetForm && !isEcheck && (
              <CreditCardForm
                submissionState={submissionState}
                extendSubmissionState={extendSubmissionState}
                creditCardInfo={creditCardInfo}
                setCreditCardInfo={(creditCard) =>
                  setCreditCardInfo(creditCard)
                }
                errors={errors}
              />
            )}

            {!isResetForm && isEcheck && (
              <PlaidLink onStripeTokenReceived={handleStripeTokenReceived} />

              /* <ACHPaymentForm
                submissionState={submissionState}
                extendSubmissionState={extendSubmissionState}
                achInfo={achInfo}
                setAchInfo={(ach) => setAchInfo(ach)}
                errors={errors}
              /> */
            )}

            <div className="d-flex justify-content-start flex-wrap align-items-center gap-3">
              <button
                className="text-uppercase btn-green border-0 py-1 px-3"
                style={{
                  borderRadius: '20px',
                  borderWidth: '2px',
                  fontSize: '14px',
                }}
                disabled={isLoading || isSubmitting}
                onClick={checkIsDonation}
                type="button"
              >
                {isLoading ? (
                  <>
                    Proccessing{' '}
                    <span
                      className="spinner-border"
                      role="status"
                      style={{
                        height: '14px',
                        width: '14px',
                      }}
                    ></span>
                  </>
                ) : (
                  'Submit'
                )}
              </button>
              <button
                onClick={resetForm}
                className="text-uppercase btn-orang border-0 py-1 px-3"
                style={{
                  borderRadius: '20px',
                  borderWidth: '2px',
                  fontSize: '14px',
                }}
                type="button"
                disabled={isLoading || isSubmitting}
              >
                Reset
              </button>
              <button
                type="button"
                className="text-uppercase btn-gray text-white border-0 py-1 px-3"
                style={{
                  borderRadius: '20px',
                  borderWidth: '2px',
                  fontSize: '14px',
                }}
                onClick={() =>
                  navigate(`/`, {
                    replace: true,
                  })
                }
                disabled={isLoading || isSubmitting}
              >
                Cancel
              </button>
            </div>
            {/* <div className='d-flex justify-content-end gap-3'>
              <button
                className='text-uppercase btn btn-outline-light text-white bg-success text-success fw-bold px-4 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg '
                style={{
                  borderRadius: '20px',
                  borderWidth: '2px',
                  fontSize: '12px',
                }}
                disabled={isLoading}
                onClick={startSubmit}
                type='button'
              >
                {isLoading ? 'Processing...' : 'Donate Now'}
              </button>
            </div> */}
            <DeclineInfo type={'One Time'} />
            <DonnationPoster />
          </div>
        </div>
      </form>
    </>
  );
};

export default OneTimeDonation;
